// https://tailwindcss.com/docs/configuration

const plugin = require('tailwindcss/plugin')

module.exports = {
  content: [
    "./index.php",
    "./app/**/*.php",
    "./resources/**/*.{php,vue,js}",
    "!./resources/blocks/*.js",
    "./config/acf.php"
  ],
  corePlugins: {
    container: false
  },
  theme: {
    fontFamily: {
      body: ['Roboto', 'sans-serif'],
      heading: ['Barlow', 'sans-serif'],
      baufra: ['Baufra', 'sans-serif']
    },
    extend: {
      backgroundImage: {
        'image': 'var(--image)'
      },
      fontSize: {
        '3xs': ['0.6875rem', {
          lineHeight: 1.2,
          letterSpacing: '0.02em'
        }],
        '2xs': ['0.75rem', {
          lineHeight: 1.2,
          letterSpacing: '0.02em'
        }],
        'xs': ['0.8125rem', {
          lineHeight: 1.5,
          letterSpacing: '0.01em'
        }],
        base: ['1rem', {
          lineHeight: 1.5,
          letterSpacing: '0.01em'
        }],
        lg: ['1.125rem', {
          lineHeight: 1.2,
          letterSpacing: '0.02em'
        }],
        xl: ['1.25rem', {
          lineHeight: 1.5,
          letterSpacing: '0.01em'
        }],
        '2xl': ['1.5rem', {
          lineHeight: 1.5,
          letterSpacing: '0.01em'
        }],
        '3xl': ['1.75rem', {
          lineHeight: 1.2,
          letterSpacing: '0.01em'
        }],
        '4xl': ['2rem', {
          lineHeight: 1,
          letterSpacing: '0.01em',
        }],
        '5xl': ['2.5rem', {
          lineHeight: 0.9
        }],
        '6xl': ['3rem', {
          lineHeight: 0.9
        }],
        '7xl': ['4rem', {
          lineHeight: 0.9
        }],
        '8xl': ['5.3125rem', {
          lineHeight: 0.8
        }],
        '9xl': ['7.5rem', {
          lineHeight: 0.8
        }],
      },
      lineHeight: {
        'tight': 1.2
      },
      colors: {
        red: '#EF3E42',
        black: '#020202',
        'off-white': '#D9D9D9'
      },
      typography: ({ theme }) => ({
        DEFAULT: {
          css: {
            blockquote: {
              paddingInlineStart: 0,
              borderInlineStartWidth: 0,
              fontStyle: 'normal',

              'p': {
                marginTop: 0,
                marginBottom: '2rem'
              },
              '& p:before': {
                display: 'none'
              },

              '& p:after': {
                display: 'none'
              }
            },
            cite: {
              fontStyle: 'normal'
            },
            li: {
              div: {
                marginTop: 0,
                marginBottom: 0
              }
            }
          }
        },
        current: {
          css: {
            '--tw-prose-body': 'currentColor',
            '--tw-prose-headings': 'currentColor',
            '--tw-prose-lead': 'currentColor',
            '--tw-prose-links': 'currentColor',
            '--tw-prose-bold': 'currentColor',
            '--tw-prose-counters': 'currentColor',
            '--tw-prose-bullets': 'currentColor',
            '--tw-prose-hr': 'currentColor',
            '--tw-prose-quotes': 'currentColor',
            '--tw-prose-quote-borders': 'currentColor',
            '--tw-prose-captions': 'currentColor',
            '--tw-prose-code': 'currentColor',
            '--tw-prose-pre-code': 'currentColor',
            '--tw-prose-pre-bg': 'currentColor',
            '--tw-prose-th-borders': 'currentColor',
            '--tw-prose-td-borders': 'currentColor'
          },
        },
      })
    },
  },
  plugins: [
    require('@tailwindcss/typography'),
    plugin(require('./tw-forms.config.js')),
    plugin(function({ addVariant }) {
      addVariant('menu', [
        '& .wp-block-navigation__container',
        '& .wp-block-navigation > ul'
      ])
      addVariant('menu-item', '& > .wp-block-navigation > ul > .wp-block-navigation-link')
      addVariant('menu-link', [
        '& > .wp-block-navigation > ul > .wp-block-navigation-link > a',
        '& .wp-block-navigation-item__content > div'
      ])
    }),
    plugin(function({ addVariant }) {
      addVariant('highlight', [
        '& :where(.is-highlight)'
      ])
    }),
  ],
}
